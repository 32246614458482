import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import axios from 'axios';
import { API_URL } from '../config';

const CertificateVerification = () => {
  const { id } = useParams();
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  console.log('CertificateVerification mounted, id:', id);
  console.log('API_URL:', API_URL);

  useEffect(() => {
    const fetchCertificate = async () => {
      console.log('Fetching certificate data...');
      try {
        console.log('Making API call to:', `${API_URL}/api/certificate/${id}`);
        const response = await axios.get(`${API_URL}/api/certificate/${id}`);
        console.log('API response:', response.data);
        setCertificate(response.data);
      } catch (err) {
        console.error('API call failed:', err);
        setError('Certificate not found or error fetching certificate details');
      } finally {
        setLoading(false);
      }
    };

    fetchCertificate();
  }, [id]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 4 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  // Filter out internal fields and logos for the table
  const tableData = Object.entries(certificate)
    .filter(([key]) => !['certificateId', 'createdAt', 'logos'].includes(key))
    .map(([key, value]) => ({
      label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
      value: value
    }));

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4 }}>
        <Paper sx={{ p: 3 }}>
          {/* Partner Logos Section */}
          {certificate.logos && certificate.logos.length > 0 && (
            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                Partner Organizations
              </Typography>
              <Grid container spacing={3} justifyContent="center">
                {certificate.logos.map((logo, index) => (
                  <Grid item key={index}>
                    <img
                      src={logo}
                      alt={`Partner logo ${index + 1}`}
                      style={{ maxHeight: 80, maxWidth: 200, objectFit: 'contain' }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          {/* Verification Status */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 4,
            }}
          >
            <VerifiedIcon color="success" sx={{ fontSize: 40, mr: 1 }} />
            <Typography variant="h4" component="h1">
              Certificate Verified
            </Typography>
          </Box>

          {/* Certificate Details Table */}
          <TableContainer>
            <Table>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: 'bold', width: '40%' }}
                    >
                      {row.label}
                    </TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Container>
  );
};

export default CertificateVerification; 